import {Link} from "react-router-dom";

function NotFound() {
  return (
    <div className="m-4">
     <div className="parent-container container d-flex flex-column align-items-center">
        <h2 className="display-4 m-4">Page Not Found</h2>
        <h3 className="display-6 m-3 text-center">Sorry, you've found a page that doesn't exist.</h3>
        <p>Check your url or navigate to the homescreen below:</p>
        <Link to="" className="toggle-button h3 text-center m-4">Back to Home</Link>
      </div>
    </div>
  );
}

export default NotFound;
