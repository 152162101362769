import React from "react";
import logoIcon from "assets/WRK-logo.webp";
import "styles/navbar.css";
import { Link } from "react-router-dom";

function LogoIcon (props) {
    const logoIconStyle = {
        height: props.height,
        width: props.width,
        verticalAlign: props.verticalAlign,
        margin: props.margin
    }

    return (
        <img src={logoIcon} style={logoIconStyle} alt="logo"></img>
    )
}

function LogoText (props) {
    return (
        <div className="logo-text">{props.logoText}</div>
    )
}

function NavbarCenter (props) {
    return (
        <Link className="navbar-center" to="" style={{textDecoration: "none"}} onClick={() => props.toggleSubnavButton("")} >
            <LogoIcon
                height="96px" 
                width="96px"
                verticalAlign="middle"
                margin="10px"
            />
            <LogoText logoText="The WRK Group's Social Impact" />
        </Link>
    )
}


function Navbar (props) {
    return (
        <div className="navbar"><NavbarCenter {...props} /></div>
    )
}

export default Navbar;