import React from "react";
import { NavLink, Outlet } from "react-router-dom";

import "../styles/subnavbar.css";


export default function Subnavbar(props) {
  return (
    <>
      <nav className="navbar-expand-lg navbar-light">
        <div className="main-container">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse button-flex" id="navbarNav">
              {props.data.map((indicator) => (
                <div className="navbar-nav" key={indicator.name} >
                    <NavLink 
                      to={`${indicator.url}`} className={props.subnavButton.value === indicator.name ? "button-link active" : "button-link"} onClick={() => props.toggleSubnavButton(indicator.name)} 
                    >
                      {indicator.name}
                    </NavLink>
                </div>
              ))}
            </div>
          </div>
      </nav>
      <Outlet />
    </>

  );
}

