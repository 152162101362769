export const ThemeColors = {
 "primary-color": "#123c63",
 "green": "#00a454",
 "blue": "#0c8ccd",
 "white": "#FFFFFF",
 "light-blue": "#ECF4FA",
 "yellow": "#ffc934",
 "orange": "#f5831f",
 "red": "#c20022",
 "gray": "#d9d9d9"
}