import { ReactComponent as HousingIcon } from "assets/icons/housing.svg";
import { ReactComponent as EducationIcon } from "assets/icons/education.svg";
import { ReactComponent as EventsIcon } from "assets/icons/events.svg";
import { ReactComponent as SafetyIcon } from "assets/icons/security.svg";
import { ReactComponent as WorkforceIcon } from "assets/icons/briefcase.svg";
import AvailableUnits from "charts/housing/availableUnits";
import OccupiedUnits from "charts/housing/occupiedUnits";
import MovedIn from "charts/housing/movedIn"



export const indicatorData = [
    {
        id: 0,
        title: "Housing",
        icon: <HousingIcon className="indicator-icon" />,
        content: [
            {
                statsID: 0,
                statsCaption: "average yearly change in duration of stay in public housing",
                statsValue: "+3.6",
                statsUnit: "months",
                statsValueColor: 1,
                statsURL: "/housing/duration",
                title: "Duration of Stay in Public Housing",
                chart: <MovedIn />
            },
            {
                statsID: 1,
                statsCaption: "average yearly change in percent of occupied public housing units ",
                statsValue: "-3.9",
                statsUnit: "%",
                statsValueColor: 0,
                statsURL: "/housing/occupied",
                title: "Occupied Units",
                chart: <OccupiedUnits />
            },
            
            {
                statsID: 2,
                statsCaption: "average yearly change in number of units available for rent in Riverside",
                statsValue: "-4.3",
                statsUnit: "units",
                statsValueColor: 0,
                statsURL: "/housing/rent",
                title: "Units Available For Rent",
                chart: <AvailableUnits />
            }
        ]
    },
    {
        id: 1,
        title: "Education",
        icon: <EducationIcon className="indicator-icon" />,
        content: [
            {
                statsID: 0,
                statsCaption: "average yearly change in 4-year high school graduation gap, compared to Delaware",
                statsValue: "+0.5",
                statsUnit: "%",
                statsValueColor: 1,
                statsURL: "/education/graduation-gap"
            },
            {
                statsID: 1,
                statsCaption: "percent of Riverside children who are ready for kindergarten, compared to Delaware",
                statsValue: "+21.9",
                statsUnit: "%",
                statsValueColor: 1,
                statsURL: "/education/kindergarten"
            },
            {
                statsID: 2,
                statsCaption: "average yearly improvement in literacy achievement gap, compared to Delaware",
                statsValue: "+3.0",
                statsUnit: "%",
                statsValueColor: 1,
                statsURL: "/education/literacy"
            },
            {
                statsID: 3,
                statsCaption: "average yearly improvement in math achievement gap, compared to Delaware",
                statsValue: "+2.1",
                statsUnit: "%",
                statsValueColor: 1,
                statsURL: "/education/math"
            }
            
        ]

    },
    {
        id: 2,
        title: "Workforce",
        icon: <WorkforceIcon className="indicator-icon" />,
        content: [
            {
                statsID: 0,
                statsCaption: "average change in employment rate in/of WRK Group's census tracts compared to Wilmington, since 2017",
                statsValue: "-2.7",
                statsUnit: "%",
                statsValueColor: 0,
                statsURL: "/workforce/employment-comparisons"
            }
        ]
    },
    {
        id: 3,
        title: "Safety",
        icon: <SafetyIcon className="indicator-icon" />,
        content: [
            {
                statsID: 0,
                statsValue: "66.8",
                statsUnit: "%",
                statsCaption: "survey participants reported feeling safe while walking during the day",
                statsValueColor: 2,
                statsURL: "/safety/walking",
            },
            {
                statsID: 1,
                statsValue: "32.8",
                statsUnit: "%",
                statsCaption: "survey participants reported feeling safe while walking at night",
                statsValueColor: 2,
                statsURL: "/safety/walking",
            }
        ]
    },
    {
        id: 4,
        title: "Events",
        icon: <EventsIcon className="indicator-icon" />,
        content: [
            
            {
                statsID: 0,
                statsValue: "86.4",
                statsCaption: "average events hosted per month at The Warehouse",
                statsUnit: "events",
                statsValueColor: 2,
                statsURL: "/events/counts",
            },
            {
                statsID: 1,
                statsValue: "1824.6",
                statsCaption: "average hours of service provided to the community per year",
                statsUnit: "hours",
                statsValueColor: 2,
                statsURL: "/events/hours",
            }
        ]
    }
]