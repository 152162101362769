const safetyData = [
    {
      name: "Safety Rating",
      url: "rating"
    },
    {
      name: "Walking at Day/Night",
      url: "walking"
    },
    {
      name: "Safety Concerns",
      url: "concerns"
    }
  ]

safetyData.sort((a, b) => a.name.localeCompare(b.name))

  export default safetyData;