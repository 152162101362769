import {WarehouseEventsStackedColumn} from "./WarehouseEventsOverTime"

export const WarehouseEventsYearly = function ({year}) {

const tooltipOptions = function () {
    const { y, location, month_abb, events_per_month, pct_per_month, year } = this.point;
    const tooltip = `In ${month_abb} ${year}, ${pct_per_month} of all events were held at <strong style = "color:${this.color} !important">${location}</strong> (${y}/${events_per_month} events)`;

    return tooltip;
  };

  return (
    <>

<WarehouseEventsStackedColumn
        year={year}
        title={"Number of Events at The Warehouse in " + year}
        tooltipOptions={tooltipOptions}
      />
    </>
  );
};