import {AvgWarehouseEventsStackedColumn} from "./WarehouseEventsOverTime"

export const AvgWarehouseEvents = function () {

const averageTooltipOptions = function () {

    const { y, location, month_abb } = this.point;
    const tooltip = `From 2020 to 2022, an average of ${y.toFixed(0)} events were held at <strong style = "color:${this.color} !important">${location}</strong> in ${month_abb}.`;

    return tooltip;
  };

  return (
    <>
<AvgWarehouseEventsStackedColumn
      title="Events at The Warehouse: 3-Year Average"
      tooltipOptions={averageTooltipOptions}/>      

    </>
  );
};