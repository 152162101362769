import { Chart } from "charts/templates/Chart";
import { useState, useEffect } from "react";
import data from '../../data/education/education_achievement.csv'; 
import {csv,autoType} from 'd3'; 
import {tidy, filter, select} from '@tidyjs/tidy';

export default function AchievementScores({filters}) {

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
      csv(data, autoType).then((d) => {
        setChartData(d);
      });
    }, []);
  
    
    const tidyChartData = 
      tidy(
        chartData,
        filter((d) => filters.contentarea.includes(d.contentarea) && d.grade===filters.grade),
        select(['district','pctproficient','schoolyear', 'contentarea'])
      );
  
      const columns = Array.from(new Set(chartData.map(d => d.district))).sort();
    
    return (
      <>
       {
         filters.contentarea.map((contentarea, index) => {
  
  
          const series = columns.map((column, i, arr) => {
            return {
              type: "line",
              name: column,
              data: tidyChartData.filter(i => i.district===column && i.contentarea === contentarea).map(d => d.pctproficient)
            }
          })

          let chartTitle; 

          switch(contentarea) {
            case "ELA":
              chartTitle = "English";
              break;
            case "SCI":
              chartTitle="Science";
              break;
            case "SOC":
              chartTitle="Social Studies";
              break; 
            case "MATH":
              chartTitle ="Math";
              break;
            default:
              chartTitle=""; 
          }

  
          const options = {
            title: { text: `${chartTitle} Performance: ${filters.grade}` },
            tooltip: {
              formatter: function() {
                return `In ${this.x}, ${this.y.toFixed(1)}% of ${filters.grade !== "All Students" ? filters.grade + "rs" : "all students"} at ${this.series.name} achieved proficiency in ${chartTitle}.`
              }
            },
            yAxis: {
              labels: {
                formatter: function () {
                  return (this.value) + "%";
                }
              },
              title: {text: undefined},
            },
            series: series,
            xAxis: { categories: [2015, 2016, 2017, 2018, 2019, 2020, 2021]}, 
            noData: `No data available for the selected grade and subject area.`
          }
  
         return  <Chart options={options} filters={filters} key={index}/>
  
        })
      }
      </>
    );
  }