import data from "data/education/all_kinder.csv";
import { Line } from 'charts/templates/Line';
import { ThemeColors } from "colors";

export default function KindergartenReadiness() {
  const tooltipOptions = function () {
    return `In ${this.x}, ${this.y.toFixed(1)}% of ${this.series.name} children were kindergarten ready.`;
  };

    const caption = "Source: <a href='https://datacenter.kidscount.org/data/tables/10050-kindergarten-readiness?loc=9&loct=2#detailed/2/any/false/1729,37,871,870/3284,3285,6044,6046,6047/19442' target='_blank'>Kids Count Data Center</a>";
    
    const moreOptions = {
      yAxis: {
        labels: {
          formatter: function () {
            return (this.value) + "%";
          }
        },
        title: {text: undefined},
      },
    };

  return (
    <>
      <Line
        data={data} 
        title="Children achieving kindergarten readiness" 
        caption = {caption}
        columns = {[
          {colName: "Riverside", name: "Riverside", color: ThemeColors.green},
          {colName: "Delaware", name: "Delaware", color: ThemeColors.gray}
          ]}
        xAxisColumn="year"
        tooltipOptions={tooltipOptions}
        moreOptions={moreOptions}
      />  
    </>
  )
}