import data from "data/education/eastside_math.csv";
import { Dumbbell } from "charts/templates/Dumbbell";

export default function MathAchievement() {
  
    const tooltipOptions = function () {
    const tooltip = 
      this.series.name === "Delaware" 
      ? `In ${this.x}, <strong>${this.y.toFixed(1)}%</strong> of ${this.series.name} students <br/> achieved math proficiency.`
      : `In ${this.x}, <strong  style="color: var(--green)">${this.y.toFixed(1)}%</strong> of ${this.series.name} students <br/> achieved math proficiency. The gap to the Delaware average was <strong>${this.point.gap.toFixed(1)}%</strong>.`

      
      return tooltip; 
        }

      const caption = "Source: <a href='https://data.delaware.gov/Education/Student-Assessment-Performance/ms6b-mt82' target='_blank'>Delaware Open Data</a>";

  return (
    <>
      <Dumbbell
        data={data}
        seriesOne={{ column: "east_side", name: "EastSide Charter" }}
        seriesTwo={{ column: "delaware", name: "Delaware" }}
        xAxisColumn="schoolyear"
        gapColumn="gap"
        tooltipOptions={tooltipOptions}
        title="Children achieving math proficiency (3rd-8th graders)"
        caption = {caption} 
      />
    </>
  );
}
