import "../styles/about.css";

import HUD from "../assets/HUD.svg";
import WRK from "../assets/WRK-logo.webp";

// TO DO:
// Need better SVGs for census, the warehouse, delaware open data, and kids count
import census from "../assets/census.svg";
import kidscount from "../assets/kidscount.svg";
import DOD from "../assets/DOD.svg";
import warehouse from "../assets/warehouse.svg";

function About() {
  return (
    <div className="m-4">
      <div className="about-header">
        <h2 className="m-0 big-font">About This Dashboard</h2>
      </div>
      <div className="container parent-container">
        <h3 className="text-center mb-3">About the Data Innovation Lab</h3>
        <div className="container">
          <h4>
          Tech Impact’s Data Innovation Lab applies innovations in 
          data science to public & social problems to grow community impact. 
          We are a data lab made up of diverse thinkers and innovators. 
          Our world-class research helps partners across various industries 
          and stakeholder levels to identify, implement, and scale solutions 
          to complex challenges – from public health and safety to housing, 
          finance, and education.
          <br></br>
          <br></br>
          Armed with open-source and in-house research, we convene policymakers, 
          entrepreneurs, corporate innovators and community advocates for 
          data-driven conversations and focused solutions. And we follow through 
          by coaching our partners as they execute on our ‘data as a service’ 
          solutions. Visit our{" "}
            <a
              href="https://techimpact.org/services/data-lab/"
              target="_blank"
              rel="noreferrer"
            >
              website
            </a>{" "}
            or <a href="mailto:labprojects@techimpact.org">email</a> us to get
            in touch.
          </h4>
        </div>
      </div>
      <div className="container parent-container">
        <h3 className="text-center mb-3">Data Sources</h3>
          <div className="child-container">
            <img
              className="logo-img"
              src={HUD}
              alt="United States Department of Housing and Urban Development logo"
            ></img>
            <div>
              <h4>Housing</h4>
              <p>
                Housing data are sourced from the{" "}
                <a
                  href="https://www.huduser.gov/portal/datasets/assthsg.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Assisted Housing data
                </a>{" "}
                from the{" "}
                <a href="https://www.hud.gov" target="_blank" rel="noreferrer">
                  US Department of Housing and Urban Development
                </a>{" "}
                (HUD).
              </p>
            </div>
          </div>
          <div className="child-container">
            <img
              className="logo-img"
              src={kidscount}
              alt="Kids Count Data Center logo"
            ></img>
            <div>
              <h4>Education</h4>
              <h5 className="font-italic">Kindergarten Readiness</h5>
              <p>
                National kindergarten readiness data are sourced from the
                <a
                  href="https://datacenter.kidscount.org/data/tables/10050-kindergarten-readiness?loc=9&loct=2#detailed/2/any/false/1729,37,871,870/3284,3285,6044,6046,6047/19442"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Kindergarten Readiness in Delaware dataset
                </a>{" "}
                from{" "}
                <a
                  href="https://datacenter.kidscount.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Kids Count Data Center
                </a>
                .
              </p>
            </div>
          </div>
          <div className="child-container">
            <img
              className="logo-img"
              src={DOD}
              alt="Delaware Open Data logo"
            ></img>
            <div>
              <h5 className="font-italic">
                High School Achievement and Graduation Rate
              </h5>
              <p>
                High school achievement and graduation rate data are sourced
                from the{" "}
                <a
                  href="https://data.delaware.gov/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Delaware Open Data Portal
                </a>
                . The high school achievement data are sourced from the{" "}
                <a
                  href="https://data.delaware.gov/Education/Student-Assessment-Performance/ms6b-mt82"
                  target="_blank"
                  rel="noreferrer"
                >
                  Student Assessment Performance dataset
                </a>
                . The high school graduation data are sourced from the{" "}
                <a
                  href="https://data.delaware.gov/Education/Student-Graduation/t7e6-zcnn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Student Graduation dataset
                </a>
                .
              </p>
              <p></p>
            </div>
          </div>
          <div className="child-container">
            <img
              className="logo-img"
              src={census}
              alt="United States Census Bureau logo"
            ></img>
            <div>
              <h4>Workforce Development</h4>

              <p>
                Employment data are sourced from the{" "}
                <a
                  href="https://www.census.gov/programs-surveys/acs"
                  target="_blank"
                  rel="noreferrer"
                >
                  5-year American Community Survey datasets
                </a>{" "}
                from the{" "}
                <a
                  href="https://www.census.gov/"
                  target="_blank"
                  rel="noreferrer"
                >
                  US Census
                </a>
                .
              </p>
            </div>
          </div>
          <div className="child-container">
            <img className="logo-img" src={WRK} alt="WRK Group logo"></img>
            <div>
              <h4>Safety</h4>
              <p>
                Safety data are sourced from the 2021{" "}
                <a
                  href="https://wrkgroup.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  WRK Group
                </a>{" "}
                Community survey. The original, participant-wise dataset is
                private.
              </p>
            </div>
          </div>
          <div className="child-container">
            <img className="logo-img" src={warehouse} alt="The Warehouse logo"></img>
            <div>
              <h4>Events</h4>
              <p>
                Events data are sourced from{" "}
                <a
                  href=" https://thewarehouse.recdesk.com/Community/Calendar"
                  target="_blank"
                  rel="noreferrer"
                >
                  The Warehouse Calendar
                </a>
                .
              </p>
            </div>
          </div>
      </div>
    </div>
  );
}

export default About;
