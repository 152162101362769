import { useState } from "react";

import "../styles/genericPage.css";
import "../styles/education-graduation.css";

import GraduationRate from "charts/education/GraduationRate";


export default function EducationGraduation() {
  const [filters, setFilters] = useState(["4-Year"])
  
  const toggleCharts = (event) => {
    setFilters([event.target.value])
  }

  return (
    <>
        <div className="flex">
          <div className="chart-container">
            <div className="header">
              <h2 className="big-font">Graduation Rate</h2>
              <div className="button-container container">
                <button className={"toggle-button " + (filters.includes("4-Year") ? ("active") : ("not-active"))} value="4-Year" onClick={(event) => toggleCharts(event)}>4 Years</button>
                <button className={"toggle-button " + (filters.includes("5-Year") ? ("active") : ("not-active"))} value="5-Year" onClick={(event) => toggleCharts(event)}>5 Years</button>
                <button className={"toggle-button " + (filters.includes("6-Year") ? ("active") : ("not-active"))} value="6-Year" onClick={(event) => toggleCharts(event)}>6 Years</button>
              </div>
            </div>
            <div className="gap-class">
              <div className="container">
                <GraduationRate filters={filters} />
              </div>
            </div>
          </div>
        </div>
    </>
  );
}
