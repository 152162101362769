import React from "react";

import "App.css";
import Home from "pages/home";
import About from "pages/about";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Subnavbar from "components/subnavbar";
import Housing from "pages/housing";
import Education from "pages/education";
import EducationGraduation from "pages/education-graduation";
import WorkforceComparisons from "pages/workforce-comparisons";
import Safety from "pages/safety";
import Workforce from "pages/workforce";
import Events from "pages/events";
import EventCounts from "pages/eventcounts";
import NotFound from "pages/not-found";
import { Footer } from "components/footer";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import educationData from "data/educationData";
import housingData from "data/housingData";
import safetyData from "data/safetyData";
import eventsData from "data/eventsData";
import workforceData from "data/workforceData";
import EducationAchievement from "pages/education-achievement";
import HousingDetails from "pages/housing-details";
import { useState } from "react";

export default function App() {

	const [subnavButton, setSubnavButton] = useState({
		value: ""
	});

	const toggleSubnavButton = (value) => {
		setSubnavButton({ ...subnavButton, value: value })
	}

	return (
		<BrowserRouter>
			<div id="page-container">
				<Navbar toggleSubnavButton={toggleSubnavButton} />
				<div className="d-flex" id="content-sidebar-wrap">
					<Sidebar toggleSubnavButton={toggleSubnavButton} />
					<div className="content-wrap">
						<Routes>
							<Route path="" element={<Home />} />
							<Route path="about" element={<About />} />

							<Route path="housing" element={<Subnavbar data={housingData} subnavButton={subnavButton} toggleSubnavButton={toggleSubnavButton} />}>
								<Route index element={<Housing />} />
								{/* Generic route, pass in dynamic data to render generic pages */}
								<Route path=":indicator" element={<Housing />} />
								{/* Specific route, for example Housing Details */}
								<Route path="housing-details" element={<HousingDetails />} />
							</Route>

							<Route path="education" element={<Subnavbar data={educationData} subnavButton={subnavButton} toggleSubnavButton={toggleSubnavButton} />}>
								<Route index element={<EducationAchievement />} />
								{/* Generic route, pass in dynamic data to render generic pages */}
								<Route path=":indicator" element={<Education />} />
								{/* Specific route - Graduation Rate*/}
								<Route path="graduation-rate" element={<EducationGraduation />} />
								{/* Specific route - Achievement Scores*/}
								<Route path="achievement-scores" element={<EducationAchievement />} />
							</Route>

							<Route path="workforce" element={<Subnavbar data={workforceData} subnavButton={subnavButton} toggleSubnavButton={toggleSubnavButton} />}>
								<Route index element={<Workforce />} />
								<Route path=":indicator" element={<Workforce />} />
								<Route path="employment-comparisons" element={<WorkforceComparisons />} />
							</Route>

							<Route path="safety" element={<Subnavbar data={safetyData} subnavButton={subnavButton} toggleSubnavButton={toggleSubnavButton} />}>
								<Route index element={<Safety />} />
								<Route path=":indicator" element={<Safety />} />
							</Route>

							<Route path="events" element={<Subnavbar data={eventsData} subnavButton={subnavButton} toggleSubnavButton={toggleSubnavButton} />}>
								<Route index element={<EventCounts />} />
								<Route path=":indicator" element={<Events />} />
								<Route path="counts" element={<EventCounts />} />
							</Route>

              				<Route path="*" element={<NotFound />}/>
						</Routes>
					</div>
				</div>
				<Footer />
			</div>
		</BrowserRouter>
	);
}

