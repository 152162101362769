const educationData = [
    {
      name: "Kindergarten Readiness",
      url: "kindergarten"
    },
    {
      name: "Literacy Proficiency",
      url: "literacy"
    },
    {
      name: "Math Proficiency",
      url: "math"
    },
    {
      name: "Graduation Gap",
      url: "graduation-gap"
    },
    {
      name: "Graduation Rate",
      url: "graduation-rate"
    },
    {
      name: "Achievement Scores",
      url: "achievement-scores"
    } 
  ]

educationData.sort((a, b) => a.name.localeCompare(b.name))

  export default educationData;