
export const outcomeName = {
    total_units: "Total Subsidized Units",
    pct_occupied: "% Occupied",
    number_reported: "Number Of Reported",
    pct_reported: "% Reported",
    months_since_report: "Average Months Since Report",
    pct_movein: "% Moved In Past Year",
    people_per_unit: "Number Of People Per Unit",
    people_total: "Total Number Of People",
    rent_per_month: "Average Household Rent Per Month",
    spending_per_month: "Average HUD Spending Per Month",
    hh_income: "Household Income Per Year",
    person_income: "Household Income Per Year Per Person",
    pct_lt5k : "% Household Income Per Year Less Than $5K",
    pct_5k_lt10k: "% Household Income Per Year $5K - $10K",
    pct_10k_lt15k: "% Household Income Per Year $10K - $15K",
    pct_15k_lt20k: "% Household Income Per Year $15K - $20K",
    pct_ge20k: "% Household Income Per Year Greater Than $20K",
    pct_wage_major: "% Household Wages Are Major Source Of Income",
    pct_welfare_major: "% Household Welfare Is Major Source Of Income",
    pct_other_major: "% Household Others Are Major Source Of Income",
    pct_median: "% Of Households With Local Median Income",
    pct_lt50_median: "% Of Households With Very Low Income ",
    pct_lt30_median: "% Of Households With Extremely Low Income ",
    pct_2adults: "% 2+ Adults With Children",
    pct_1adult: "% 1 Adult With Children",
    pct_female_head: "% Female Head",
    pct_female_head_child: "% Female Head With Children",
    pct_disabled_lt62: "% Of Households Adult Under 62 With Disability",
    pct_disabled_ge62: "% Of Households Adult Age 62+ With Disability",
    pct_disabled_all: "% Of Households With Disability",
    pct_lt24_head: "% Of HH Or Spouse Are Age 24 Or Younger",
    pct_age25_50: "% Of HH  Or Spouse Are Age 25 - 50",
    pct_age51_61: "% Of HH  Or Spouse Are Age 51 - 61",
    pct_age62plus: "% Of HH  Or Spouse Are Age 62 Or Older",
    pct_age85plus: "% Of HH  Or Spouse Are Age 85 Or Older",
    pct_minority: "% Of Households Head Minority",
    pct_black_nonhsp: "% Of Households Head Black Non-Hispanic",
    pct_native_american_nonhsp: "% Of HH Native American Non-Hispanic",
    pct_asian_pacific_nonhsp: "% Of HH Asian Or Pacific Islander Non-Hispanic",
    pct_white_nothsp: "% Of Households Head White Non-Hispanic",
    pct_black_hsp: "% Of Households Head Black Hispanic",
    pct_wht_hsp: "% Of Households Head White Hispanic",
    pct_oth_hsp: "% Of Households Head Other  Hispanic",
    pct_hispanic: "% Of Households Head Hispanic",
    pct_multi: "% Of Households Head Multiple Races",
    months_waiting: "Average Months On Waiting List",
    months_from_movein: "Average Months Since Moved In",
    pct_utility_allow: "% Of Households With Utility Allowance",
    ave_util_allow: "Average Houlsehold Utility Alllowance",
    pct_bed1: "% Of Households With 0 - 1 Bedroom",
    pct_bed2: "% Of Households With 2 Bedrooms",
    pct_bed3: "% Of Households With 3 Or More Bedroom",
    pct_overhoused: "% Of Households With More Bedrooms Than People",
    tpoverty: "% Of Households In Tract Below Proverty",
    tminority: "% Of Households In Tract Are Minority",
    tpct_ownsfd: "% Of Households In Tract Single Family Owners",
}