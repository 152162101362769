import TILogo from "assets/techimpact_mainlogo_clear_space.svg";
import "styles/footer.css";

export const Footer = function () {

return (
    <footer>
        <div className="footer-contact">
            <span style={{"fontFamily": "Oswald", "fontWeight":600}}>Contact Us: </span>
        <a href="https://techimpact.org/" target="_blank" rel="noreferrer"><img className="footer-logo" src={TILogo} alt = "TechImpact homepage"></img></a>
        </div>
    </footer>
  );


};
