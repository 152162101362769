import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "styles/genericPage.css";

import GraduationGap from "charts/education/GraduationGap";
import KindergartenReadiness from "charts/education/KindergartenReadiness";
import LiteracyAchievement from "charts/education/LiteracyAchievement";
import MathAchievement from "charts/education/MathAchievement";

function Education() {

  const [educationData, setEducationData] = useState([]);
  const { indicator } = useParams();

  useEffect(() => {
    switch (indicator) {

        case "graduation-gap":
        setEducationData([
        {
            title: "Graduation Gap",
            indicator: "+0.5%",
            value: "average yearly improvement in 4-year high school graduation gap, compared to Delaware",
            notes: [
            "The gap in the graduation rate relative to the Delaware average increased by about 0.11% per year from 2015 to 2020.",
            "However, from 2016 to 2020, the gap shrunk by an average of .5% per year, which suggests an improvement relative to the Delaware average.",
            ],
            chart: <GraduationGap />

        },
        ]);
        break;

        case "kindergarten":
        setEducationData([
        {
            title: "Kindergarten Readiness",
            indicator: "+21.9%",
            value: "percent of Riverside children who are ready for kindergarten, compared to Delaware ",
            notes: [
            "On average, 77.3% of children in Riverside were ready for kindergarten (2019-2021), compared to the Delaware average of 63.4% (2016-2019).",
            "The rate of kindergarten readiness in Riverside is 21.9% higher than the Delaware average.",
            ],
            chart: <KindergartenReadiness/>
        },
        ]);
        break;

      case "literacy":
        setEducationData([
          {
            title: "Literacy Proficiency",
            indicator: "+3.0% per year",
            value: "average yearly improvement in literacy achievement gap, compared to Delaware",
            notes: [
              "In 2021, 30.6% of 3rd-8th graders at EastSide Charter School in Riverside were proficient in reading, compared to the Delaware average of 40.7%.",
              "The gap relative to the Delaware average was -10%. It has been improving by an average of 3% per year.",
            ],
            chart: <LiteracyAchievement />
          },
        ]);
        break;

      case "math":
        setEducationData([
          {
            title: "Math Proficiency",
            indicator: "+2.1% per year",
            value: "average yearly improvement in math achievement gap, compared to Delaware",
            notes: [
              "In 2021, 23.8% of 3rd-8th graders at EastSide Charter School in Riverside were proficient in math, compared to the Delaware average of 26.2%.",
              "The gap relative to the Delaware average was –2.4%. It has been improving by an average of 2.1% a year.",
            ],
            chart: <MathAchievement />
          },
        ]);
        break;

        default:
            setEducationData([])
            break;
    }
  }, [indicator]);

  return (
    <>
      {educationData.map((item) => (
        <div className="flex" key={item.title}>
          <div className="chart-container">
            <div className="header">
              <h2 className="m-0 big-font">{item.title}</h2>
            </div>
            <div className="gap-class">
              {item.chart}
            </div>
          </div>
          {item.value ? (
          <div className="notes-container">
            <div className="value-box gap-class">
              <h3 className="big-font">{item.indicator}</h3>
              <h4>{item.value}</h4>
            </div>
          
            {item.notes ? (
            <div className="notes gap-class">
              <h3 className="text-center big-font m-0">Notes</h3>
              <hr></hr>
              <ul>
                {item.notes.map((note, i) => (
                  <li key={i}>
                    <h4>{note}</h4>
                    <br></br>
                  </li>
                ))}
              </ul> 
            </div>) : ("")}
          </div>) : ("")}
        </div>
      ))}
    </>
  );
}

export default Education;
