const housingData = [
    {
      name: "Units Available for Rent",
      url: "rent"
    },
    {
      name: "Occupied Units",
      url: "occupied"
    },
    {
      name: "Duration of Stay in Public Housing",
      url: "duration"
    }
  ]

housingData.sort((a, b) => a.name.localeCompare(b.name))


  export default housingData;