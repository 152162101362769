const workforceData = [
    {
      name: "Employment Comparisons",
      url: "employment-comparisons"
    },
    {
      name: "All Employment Rates",
      url: "employment-all"
    }
  ]

  workforceData.sort((a, b) => a.name.localeCompare(b.name))

  export default workforceData;