import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TopEventsStackedColumn } from "charts/events/TopEvents";
import { EventHoursPerWeek } from "charts/events/EventHoursPerWeek";

function Events() {
  const [eventsData, setEventsData] = useState([]);
  const { indicator } = useParams();

  useEffect(() => {
    switch (indicator) {
      case "hours":
        setEventsData([
          {
            title: "Total Event Hours Per Week",
            indicator: "1837.6 hours per year",
            value: "service provided to the community",
            notes: null,
            chart: <EventHoursPerWeek />,
          },
        ]);
        break;

      case "top":
        setEventsData([
          {
            title: "Top Events",
            indicator: "1837.6 hours per year",
            value: "service provided to the community",
            notes: null,
            chart: <TopEventsStackedColumn />,
          },
        ]);
        break;

      default:
        setEventsData([{
          title: "Total Event Hours Per Week",
          indicator: "1837.6 hours per year",
          value: "service provided to the community",
          notes: null,
          chart: <EventHoursPerWeek />,
        }]);
        break;
    }
  }, [indicator]);

  return (
    <>
      {eventsData.map((item) => (
        <div className="flex" key={item.title}>
          <div className="chart-container">
            <div className="header">
              <h2 className="m-0 big-font">{item.title}</h2>
            </div>
            <div className="gap-class">{item.chart}</div>
          </div>
          {item.value ? (
            <div className="notes-container">
              <div className="value-box gap-class">
                <h3 className="big-font">{item.indicator}</h3>
                <h4>{item.value}</h4>
              </div>

              {item.notes ? (
                <div className="notes gap-class">
                  <h3 className="text-center big-font">Notes</h3>
                  <hr></hr>
                  <ul>
                    {item.notes.map((note, i) => (
                      <li key={i}>
                        <h4>{note}</h4>
                        <br></br>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ))}
    </>
  );
}

export default Events;
