import { csv, autoType } from "d3";
import { useState, useEffect } from "react";
import { tidy, select } from "@tidyjs/tidy";
import { Chart } from "./Chart";

export const Dumbbell = function ({
  data,
  seriesOne,
  seriesTwo,
  gapColumn,
  xAxisColumn,
  tooltipOptions,
  title,
  caption,
  moreOptions,
}) {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    csv(data, autoType).then((d) => {
      setChartData(d);
    });
  }, [data]);

  const columnOne = tidy(chartData, select([seriesOne.column]));
  const columnTwo = tidy(chartData, select([seriesTwo.column]));

  const series = [
    {
      type: "scatter",
      name: seriesOne.name,
      
      data: columnOne.map((item, i) => {
        return { 
            y: item[seriesOne.column], 
            x: item[xAxisColumn],
            gap: chartData[i][gapColumn] };
      }),
      marker: {
        fillColor: "#00A454",
        radius: 7,
        symbol: "circle",
      },
    },
    {
      type: "scatter",
      name: seriesTwo.name,
      data: columnTwo.map((item, i) => {
        return { y: item[seriesTwo.column], gap: chartData[i][gapColumn] };
      }),
      marker: {
        fillColor: "#BEBEBE",
        radius: 7,
        symbol: "circle",
      },
    },
    {
      type: "columnrange",
      showInLegend: false,
      data: columnOne.map((item, i) => [
        item[seriesOne.column],
        columnTwo[i][seriesTwo.column],
      ]),
      pointWidth: 3,
      color: "#BEBEBE",
      zIndex: -1,
      animation: true,
      enableMouseTracking: false,
    },
  ];

  

  const options = {
    title: {
      text: title,
    },
    caption: {
      text: caption,
      useHTML: true,
    },
    series: series,
    tooltip: {
      formatter: tooltipOptions,
    },
    xAxis: {
      categories: chartData.map((item) => item[xAxisColumn]),
    },
    yAxis: {
      labels: {
        formatter: function () {
          return this.value + "%";
        },
      },
      title: {
        text: undefined,
      },
    },
  };
  
  return (
    <div>
      <Chart data={chartData} options={{ ...options, ...moreOptions }} />
    </div>
  );
};
