import data from "data/workforce/employed_wide.csv";
import { Dumbbell } from "charts/templates/Dumbbell";

export default function EmploymentDumbbell({seriesOneColumn, seriesOneName, gapColumn}) { 
    const tooltipOptions = function () {
        let tooltip = `In ${this.series.name}, ${(this.y * 100).toFixed(
          1
        )}% of workers were employed in ${this.key}.`;
    
        let gap = (this.point.gap * 100).toFixed(1);
    
        this.series.name !== "Wilmington"
          ? (tooltip += `<br/>The gap compared to Wilmington was ${
              gap > 0 ? "+" + gap : gap
            }%.`)
          : (tooltip += "");
    
        return tooltip;
      };
    
      const caption =
        "Source: <a href='https://www.census.gov/' target='_blank'>Census 5-Year ACS</a>";
    
      const moreOptions = {
        yAxis: {
          labels: {
            formatter: function () {
              return (this.value * 100).toFixed(0) + "%";
            },
          },
          title: {text: undefined}
        },
      };

      return (
        <>
          <Dumbbell
            data={data}
            seriesOne={{ column: seriesOneColumn, name: seriesOneName }}
            seriesTwo={{ column: "Wilmington", name: "Wilmington" }}
            xAxisColumn="year"
            gapColumn={gapColumn}
            tooltipOptions={tooltipOptions}
            title={`Employment rate of ${seriesOneName === "WRK Group" ? "WRK Group (all 3 tracts)" : seriesOneName} compared to Wilmington`}
            caption={caption}
            moreOptions={moreOptions}
          />
        </>
      );
}