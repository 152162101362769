import { Chart } from "charts/templates/Chart";
import { ThemeColors } from "colors";
import { useState, useEffect } from "react";
import data from 'data/housing/hud_DE_combined.csv'
import {csv,autoType} from 'd3'; 
import {tidy, filter, select, summarize, sum, mean, groupBy, rename} from '@tidyjs/tidy';

export default function HousingDetailsChart({tract, method, interestVar, color}) {
 
    const allTracts = [10003003002, 10003000601, 10003000602]; 

    const [chartData, setChartData] = useState([]); 

    useEffect(() => { csv(data, autoType)
                        .then(d => { setChartData(d) }) 
                    }, []); 

    const years = ["2017","2018","2019","2020","2021"]; 

    let summaryData;
    
    if (tract.name !== "Delaware" && tract.name !== "3 Tracts") {
        
        summaryData = tidy(
            chartData,
            filter(d => d.code === tract.code),
            select(["year", "name", "code", interestVar.variable]),
            rename({[interestVar.variable]: "y"})
        )
    } else if (tract.name === "Delaware") {
        if(method === "Sum") {
            summaryData = tidy(
                chartData,
                select(["year","name","code",interestVar.variable]),
                groupBy('year', [
                    summarize({ y: sum(interestVar.variable) })
                    ])
            )
        } else { 
            summaryData = tidy(
                chartData,
                select(["year","name","code",interestVar.variable]),
                groupBy('year', [
                    summarize({ y: mean(interestVar.variable) })
                ])
            )
        }
    }  else if (tract.name === "3 Tracts") {
        
        if(method === "Sum") {
            summaryData = tidy(
                chartData,
                filter(d => allTracts.includes(d.code)),
                select(["year","name","code",interestVar.variable]),
                groupBy('year', [
                    summarize({ y: sum(interestVar.variable) })
                ])
            )
        } else { 
            summaryData = tidy(
                chartData,
                filter(d => allTracts.includes(d.code)),
                select(["year","name","code",interestVar.variable]),
                groupBy('year', [
                    summarize({ y: mean(interestVar.variable) })
                ])
            )
        }
    }
    
    const series = {
            type: "column",
            color: ThemeColors.green,
            data: summaryData.map(d => {
                    return {
                    y: d.y,
                    year: d.year,
                    code: d.code
                }
                })
        }
    
    const options = {
        title: {
            text: interestVar.name + ": " + tract.name          
        },
        series: series,
        xAxis: { type: "category", categories: years },
        yAxis: {
            title: {text: ""},
            labels: {
                formatter: function () {
                    return interestVar.name[0] === "%" ? (this.value) + "%" : this.value;
                }
            },
        },
        legend: { enabled: false }, 
        tooltip: {
            formatter: function() { 
                return (
                    `<strong>Year: ${this.point.year}</strong> <br/> 
                    <strong>${method} of ${interestVar.name} for ${tract.name}:</strong> ${this.y.toFixed(1)}`
                )
            }
        },
        colors: [color]
    }

    return (
        <>
            <Chart options={options}/>
        </>
    )
    

}