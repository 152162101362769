import { Chart } from "./Chart";
import { csv, autoType } from "d3";
import { useState, useEffect } from "react";

export const Column = function ({
  data,
  column,
  xAxisColumn,
  columnColor,
  title,
  caption,
  moreOptions,
  seriesName,
  yAxisLabel,
  tooltipOptions
}) {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    csv(data, autoType).then((d) => {
      setChartData(d);
    });
  }, [data]);

  const series = [
    {
      type: "column",
      name: seriesName,
      data: chartData.map((item) => item[column]),
      color: columnColor,
    },
  ];


  const options = {
    title: {
      text: title,
    },
    caption: {
      text: caption,
      useHTML: true,
    },
    series: series,
    tooltip: {
      formatter: tooltipOptions,
    },
    xAxis: {
      categories: chartData.map((item) => item[xAxisColumn]),
    },
    yAxis: {
      title: {
        text: yAxisLabel,
      },
    }
  };

 

  return (
    <div>
      {chartData === undefined ? (
        <strong>Loading</strong>
      ) : (
        <Chart options={{ ...options, ...moreOptions }} />
      )}
    </div>
  );
};
