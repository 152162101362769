import React from "react";

import { Link, NavLink } from "react-router-dom";
import "../styles/sidebar.css";

import education from "../assets/icons/education.svg";
import housing from "../assets/icons/housing.svg";
import workforce from "../assets/icons/briefcase.svg";
import safety from "../assets/icons/security.svg";
import events from "../assets/icons/events.svg";
import info from "../assets/icons/about.svg";
import techimpactsmall from "../assets/techimpact-nowords.svg";


export default function SideBar(props) {

  const sidebarData = [
    {
      name: "housing",
      image: housing,
      url: "/duration",
      view: "Duration of Stay in Public Housing"
    },
    {
      name: "education",
      image: education,
      url: "/achievement-scores",
      view: "Achievement Scores"
    },
    {
      name: "workforce",
      image: workforce,
      url: "",
      view: "All Employment Rates"
    },
    {
      name: "safety",
      image: safety,
      url: "/concerns",
      view: "Safety Concerns"
    },
    {
      name: "events",
      image: events,
      url: "/counts",
      view: "Number of Events"
    },
  ];

  return (
    <nav className="side-panel">
      <div>
        <div className={"side-panel-header"}>
          <Link className="home-button home-button-size" to="" data-tooltip="Home"> 
            {/* <img className="home-button-size icon" src={home}></img> */}
            <h3 className="home-button-text" onClick={() => props.toggleSubnavButton("")}>Home</h3>
          </Link>
        </div>
        <hr></hr>
        {sidebarData.map((item) => {
          return (
            <div className="side-panel-item" key={item.name}  >
              <NavLink to={`${item.name}`} className="button-styles button-styles-color" data-tooltip={item.name} onClick={() => props.toggleSubnavButton(item.view)}>
                <img className="icon" alt={item.name} src={item.image}></img>
              </NavLink>
            </div>
          );
        })}
      <hr></hr>
      </div>
      <div className="logo-flex">
        <div className="side-panel-item">
          <NavLink className="home-button button-styles info-button-size" to="/about" data-tooltip="More Info"> 
            <img className="icon" alt="About this Dashboard" src={info}></img>
          </NavLink>
        </div>
        <img id="techimpact-logo-small" src={techimpactsmall} alt="Tech Impact wordless logo" />
      </div>
    </nav>
  );
}