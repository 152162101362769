import { useState } from "react";

import "../styles/genericPage.css";
import "../styles/education-graduation.css";

import EmploymentDumbbell from "charts/workforce/EmploymentDumbbell";

function WorkforceComparisons() {

  const [employmentRateData, setEmploymentRateData] = useState([
    {title: "WRK Group compared to Wilmington", indicator: "-2.7% per year",
    value: "average change in employment rate of WRK Group's census tracts compared to Wilmington, since 2017",
    notes: ["In the 3 census tracts served by the WRK group, the gap in employment rate to the Wilmington's employment rate have been improving on average by 0.2% per year from 2011 to 2020.", 
    "However, the gaps are worsening since 2017 by -2.7% per year."], chart: <EmploymentDumbbell seriesOneColumn="WRK" seriesOneName="WRK Group" gapColumn ="WRK_gap_to_Wilmington" />}
  ]);
  const [button, setButton] = useState("WRK");

  const toggleCharts = (event) => {
    switch (event.target.value) {
      case "Riverside":
        setEmploymentRateData([
          {title: "Riverside compared to Wilmington", indicator: "-2.7% per year",
          value: "average change in employment rate of WRK Group's census tracts compared to Wilmington, since 2017",
          notes: ["In the 3 census tracts served by the WRK group, the gap in employment rate to the Wilmington's employment rate have been improving on average by 0.2% per year from 2011 to 2020.", 
          "However, the gaps are worsening since 2017 by -2.7% per year."], chart: <EmploymentDumbbell seriesOneColumn="Riverside" seriesOneName="Riverside" gapColumn ="Riverside_gap_to_Wilmington" />}
        ])
        setButton("Riverside");
        break;

      case "Eastlake":
        setEmploymentRateData([
          {title: "Eastlake compared to Wilmington", indicator: "-2.7% per year",
          value: "average change in employment rate of WRK Group's census tracts compared to Wilmington, since 2017",
          notes: ["In the 3 census tracts served by the WRK group, the gap in employment rate to the Wilmington's employment rate have been improving on average by 0.2% per year from 2011 to 2020.", 
          "However, the gaps are worsening since 2017 by -2.7% per year."], chart: <EmploymentDumbbell seriesOneColumn="Eastlake" seriesOneName="Eastlake" gapColumn ="Eastlake_gap_to_Wilmington" />}
        ])
        setButton("Eastlake");
        break;

      case "Northeast":
        setEmploymentRateData([
          {title: "Northeast compared to Wilmington", indicator: "-2.7% per year",
          value: "average change in employment rate of WRK Group's census tracts compared to Wilmington, since 2017",
          notes: ["In the 3 census tracts served by the WRK group, the gap in employment rate to the Wilmington's employment rate have been improving on average by 0.2% per year from 2011 to 2020.", 
          "However, the gaps are worsening since 2017 by -2.7% per year."], chart: <EmploymentDumbbell seriesOneColumn="Northeast" seriesOneName="Northeast" gapColumn ="Northeast_gap_to_Wilmington" />}
        ])
        setButton("Northeast");
        break;

        default:
        setEmploymentRateData([
          {title: "WRK Group compared to Wilmington", indicator: "-2.7% per year",
          value: "average change in employment rate of WRK Group's census tracts compared to Wilmington, since 2017",
          notes: ["In the 3 census tracts served by the WRK group, the gap in employment rate to the Wilmington's employment rate have been improving on average by 0.2% per year from 2011 to 2020.", 
          "However, the gaps are worsening since 2017 by -2.7% per year."], chart: <EmploymentDumbbell seriesOneColumn="WRK" seriesOneName="WRK Group" gapColumn ="WRK_gap_to_Wilmington" />}
        ])
        setButton("WRK");
        break;
    }
  };

  return (
    <>
      {employmentRateData.map((item) => (
        <div className="flex" key={item.title}>
          <div className="chart-container container">
            <div className="header">
              <h2 className="big-font">Employment Comparisons</h2>
            </div>
            <div className="button-container">
                <button className={"toggle-button " + (button === "WRK" ? "active" : "not-active")} value="WRK" onClick={(event) => toggleCharts(event)}>
                  WRK
                </button>
                <button className={"toggle-button " + (button === "Riverside" ? "active" : "not-active")} value="Riverside" onClick={(event) => toggleCharts(event)}>
                  Riverside
                </button>
                <button className={"toggle-button " + (button === "Eastlake" ? "active" : "not-active")} value="Eastlake" onClick={(event) => toggleCharts(event)}>
                  Eastlake
                </button>
                <button className={"toggle-button " + (button === "Northeast" ? "active" : "not-active")} value="Northeast" onClick={(event) => toggleCharts(event)}>
                  Northeast
                </button>
              </div>
            <div className="gap-class">{item.chart}</div>
          </div>
          {item.value ? (
            <div className="notes-container">
              <div className="value-box gap-class">
                <h3 className="big-font">{item.indicator}</h3>
                <h4>{item.value}</h4>
              </div>

              {item.notes ? (
                <div className="notes gap-class">
                  <h3 className="text-center big-font m-0">Notes</h3>
                  <hr></hr>
                  <ul>
                    {item.notes.map((note, i) => (
                      <li key={i}>
                        <h4>{note}</h4>
                        <br></br>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ))}
    </>
  );
}

export default WorkforceComparisons;
