
import HomeBoard from "components/homeBoard";

function Home () {
    return (
        <HomeBoard />
    )
}

export default Home;
